.about-us-page {
  font-family: "Poppins";

  p {
    line-height: 2;
  }
}

@media screen and (max-width: 1200px) {
  .content {
    width: 65% !important;
  }
}

@media screen and (max-width: 850px) {
  .content {
    width: 75% !important;
  }
}

@media screen and (max-width: 550px) {
  .content {
    width: 85% !important;
  }
}