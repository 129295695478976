form.sign-in-form{
    width: 55%;
    height: fit-content;
    text-align: center;
    margin: 0 auto;
    padding-top: 1%;
    font-family: 'Poppins', sans-serif;
    background-color: #1E1E1E;
    box-shadow: 1px 1px 10px 10px rgba(0, 0, 0, 0.315);
    padding: 2.5em;
    border-radius: 20px;
    z-index: 1;

    div.logo{
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75%;
        height: 30%;

        img{
            margin: auto;
            height: 100%;
            width: 75%;
        }
    }

    .title{
        color: white;
        display: block;
        font: normal 400 1.7rem 'Poppins';
    }

    .subtitle{
        color: #afaeae;
        display: block;
        font-weight: 400;
        font: normal 400 1rem 'Poppins';
    }

    input.form-input{
        border-radius: 4px;
        border: none;
        background-color: #464646;
        color: white;
        height: 55px;
    }

    input.form-input:focus{
        border: 1px ridge #8B409D;    
    }

    .input-password{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 55px;

        input{
            height: 100%;
            width: 90%;
            background-color: #464646;
            color: white;
            border: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        div.eye-password{
            height: 100%;
            background-color: #464646;
            width: 10%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;

            i{
                display: flex;
                align-items: center;
                color: white;
                z-index: 1;
                cursor: pointer;
                font-size: 100%;
            }
        }
    }
    
    div.create-recover{
        width: 100%;
        display: flex;
        justify-content: space-between;

        .text{
            a{
                font: normal 400 0.9rem 'Poppins';
                text-decoration: none;
                color: #BDBDBD;
            }
        }
    }

    button.login{
        background-color: #8B409D;
        font-weight: 500;
    }

    button.login:hover{
        background-color: #803792;
    }

    div{
        display: flex;
        justify-content: center;
        align-items: center;

        div{
            width: 40%;

            hr{
                color: white;
                width: 100%;
            }
        }

        .option{
            color: white;
            width: 20%;
        }
    }

    button.with-google{
        background-color: #EBEBEB;
        color: #757575;
        font-weight: 400;
    }

    button.with-google:hover{
        background-color: #dbd6d6;
        color: #757575;
    }
    
    button.login, button.with-google{
        width: 100%;
        border: none;
        border-radius: 2px;
    }

    .text-muted{
        display: block;
    }

    .visitant{
        color: white;
        text-decoration: none;
    }
}

@media screen and (max-width: 768px) {
    form.sign-in-form{
        width: 100% !important;
        box-shadow: none;
        border-radius: 0;
    }
}

@media screen and (max-width: 500px) {
    form.sign-in-form{
        width: 100% !important;
        box-shadow: none;
        border-radius: 0;

        div.logo{
            width: 100%;
        }
    }
}