.help-page {
  width: 100vw;
  height: 100vh;
  font-family: "Poppins";
  text-align: justify;

  :is(section) {
    padding: 3em 0 0 0;

    p {
      line-height: 2;
    }
  }
}

.accordion-button{
  background-color: #1e1e1e;
  color: white;
}

.accordion-button::after{
  background-color: white;
  border-radius: 10px;
}

.accordion-button:focus{
  background-color: #1e1e1e;
  color: white;
}

.accordion-button:not(.collapsed){
  background-color: #1e1e1e;
  color: white;
}