.home-page {
  height: max-content;
  width: 100vw;

  .slider {
    .slider-image {
      filter: blur(2px) brightness(70%);
    }

    .slider-btn-details {
      font-size: smaller;
    }
  }
}

.text-light {
  color: white !important;
}

.home-download-section {
  height: 60vh;

  .content {
    .motivate-text {
      height: 75% !important;
    }
  }

  .mobileMockup {
    filter: drop-shadow(0px 0px 10px black);
    border-radius: 15px;
  }
}

@media screen and (min-width: 1441px) {
  .download-buttons {
    width: 80% !important;
  }
}

@media screen and (max-width: 800px) {
  .home-download-section {
    width: 100% !important;
    height: auto;
    flex-direction: column;

    .content {
      height: auto !important;
      margin: auto;
      text-align: center;

      .motivate-text {
        margin-bottom: 1rem;
      }

      .download-buttons {
        justify-content: center;
        margin: auto;
      }
    }

    .app-image {
      height: 50vh !important;
      margin: 3rem auto 0 auto !important;

      img {
        width: 100%;
        margin: auto;
      }
    }
  }
}
