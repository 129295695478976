.cart-card{
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border: 1px solid rgba(255, 255, 255, 0.398);
  border-radius: 10px;
  min-height: 125px;

  aside.image{
    width: 15%;
  }

  aside.event-informations{
    width: 65%;
    margin-left: 2.5%;

    h5{
      margin: 0;
    }
  }

  aside.close-button{
    width: 20%;
    margin-left: 2.5%;
    text-align: right;

    button{
      background-color: red;
      border: none;
      border-radius: 10px;
    }
  }
}