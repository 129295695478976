.event-card-widget {
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;

  .head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
      aspect-ratio: 1/1;
      border-radius: 100%;
      border-bottom: 2px solid white;
    }

    h5 {
      font-weight: 300;
    }

    .infos {
      .list {
        .info-row {
          text-align: center;
          small {
            color: gray;
            font-weight: 200;
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .details-button{
    background-color: rebeccapurple;
    border: none;
    border-start-end-radius: 0px;
    border-start-start-radius: 0px;
    border-end-end-radius: 10px;
    border-end-start-radius: 10px;
  }
}
