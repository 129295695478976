form.new-password-form{
    width: 37%;
    height: fit-content;
    text-align: center;
    margin: 0 auto;
    padding-top: 1%;
    font-family: 'Poppins', sans-serif;
    padding: 2.5em;
    border-radius: 20px;
    z-index: 1;

    div.logo{
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75%;
        height: 30%;

        img{
            margin: auto;
            height: 100%;
            width: 75%;
        }
    }

    input.form-input{
        border-radius: 4px;
        border: none;
        background-color: #464646;
        color: white;
        height: 8vh;
    }

    input.form-input:focus{
        border: 1px ridge #8B409D;
    }

    div.eye-password{
        position: absolute;
        width: 5%;
        height: 8vh;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 25%;

        i{
            display: flex;
            align-items: center;
            color: white;
            z-index: 1;
            cursor: pointer;
        }
    }

    button.new{
        background-color: #8B409D;
        font-weight: 500;
        border: none;
    }

    button.new:hover{
        background-color: #803792;
    }
}