form.recover-password-form{
    width: 30%;
    height: fit-content;
    text-align: center;
    margin: 0 auto;
    padding-top: 1%;
    font-family: 'Poppins', sans-serif;
    padding: 2.5em;
    border-radius: 20px;
    z-index: 1;

    div.logo{
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 30%;

        img{
            margin: auto;
            height: 100%;
            width: 100%;
        }
    }

    input.form-input{
        border-radius: 4px;
        border: none;
        background-color: #464646;
        color: white;
        height: 55px;
    }

    input.form-input:focus{
        border: 1px ridge #8B409D;
    }

    button.recover{
        background-color: #8B409D;
        font-weight: 500;
        border: none;
        width: 100%;
        font-size: 1rem;
    }

    button.recover:hover{
        background-color: #803792;
    }
}