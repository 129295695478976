.profile-page {
    width: 100vw;
    height: 100vh;

    .case-success-widget{
        position: relative;

        .logout-area{
            position: absolute;
            width: 75%;
            margin-left: 12.5%;
            display: flex;
            justify-content: flex-end;
        }
    }

    .case-error-widget {
        height: 85vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        :is(h4, p) {
            font-weight: 100;
        }

        :is(a) {
            margin: 0 .5em;
            font-weight: 400;
        }
    }
}


@media screen and (max-width: 650px) {
    .case-success-widget > h4{
        padding-top: 4rem;
    }

    .profile-form{
        padding-top: 2rem;
    }
}