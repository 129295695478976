.payment-page{
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;

  .row-cards{
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}

.cards-methods{
  display: flex;
  gap: 15px;
}

div.card{
  border-width: 2px;
  border-color: gray;
}

@media screen and (max-width: 1024px){
  .payment-form{
    width: 75% !important;
  }
}