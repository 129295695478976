.sign-up-page{
    width: 100vw;
    height: 100vh;
    margin: auto;
    background-color: #1E1E1E;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    position: relative;

    .image-background{
        background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../../../assets/images/background_login.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
    }
}