footer {
    hr {
        background-color: #FFF;
        height: 1px;
        border-radius: 100%;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {

            a,
            button {
                color: gray;
                font-weight: 200;
                text-decoration: none;
                transition: 0.2s ease-in-out;
                background-color: none;
            }

            a:hover {
                color: #FFF;
            }

            button:hover {
                color: #FFF;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    div.footer-widget {
        div.col {
            text-align: center;
            margin-top: 1rem !important;
        }
    }
}