.cart-page{
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;

  div{

    div.buy-button{
      button{
        background-color: rebeccapurple;
        border: none;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .cart-content{
    width: 75% !important;
  }
}