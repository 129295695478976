/* definindo variáveis para cores */
:root {
  --bs-darkness: #2a2a2a;
  --bs-dark: #1e1e1e;
  --bs-darker: #1a1a1a;
  --bs-lighter: rgb(220, 220, 220);
}

/* Sobrescrevendo classes padrão do Bootstrap */
/* A marcação !important dá um peso maior a regra, sobrescrevendo regras prévias */
.bg-darkness {
  background-color: var(--bs-darkness) !important;
}

.bg-dark {
  background-color: var(--bs-dark) !important;
}

.bg-darker {
  background-color: var(--bs-darker) !important;
}

.bg-lighter {
  background-color: var(--bs-lighter) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

* {
  font-family: "Poppins";
}

#notificationsQueue {
  width: 100vw;
  position: fixed;
  z-index: 10;
}

#notificationsQueue > .notification {
  max-height: max-content;
  text-overflow: ellipsis;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  animation: notificationAnimation 1.5s;
}

@keyframes notificationAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

body{
  overflow-x: hidden;
}

:is(input):focus {
  border: none;
  box-shadow: 0 0 0 0 !important;
  border: 0 none !important;
  outline: 0 !important;
}
