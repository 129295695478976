$accentColor : #C7C7C7;
$searchBarBg : #323232;

.events-page {
    width: 100vw;
    min-height: 100vh;
}

.searchbar-events {
    :is(input, span),
    :is(input):focus {
        background-color: $searchBarBg;
        color: $accentColor;
        // Fontweight
        box-shadow: 0 0 0 0;
        border: 0 none;
        outline: 0;
    }

    :is(input) {
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
        font-size: medium;
    }

    :is(span) {
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
        cursor: pointer;
    }
}

.filter-button{
    background-color: rebeccapurple;
    border: none;
}