.profile-form{
    height: fit-content;
    margin: 5% auto 0 auto;

    .title{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2%;

        h2{
            font-family: 'Poppins';
            color: white;
        }

        small{
            color: rgb(156, 156, 156);
            font-size: 1rem;
            cursor: pointer;
        }

        small:hover{
            color: rgb(197, 197, 197);
        }
    }

    div.row{
        div.col{
            input.form-input{
                border-radius: 4px;
                border: none;
                background-color: #464646;
                color: white;
                height: 55px;
            }
        
            input.form-input:focus{
                border: 1px ridge #8B409D;
            }

            button{
                width: 100%;
                height: 55px;
                background-color: #8B409D;
                font-weight: 500;
                border: none;
            }
        
            button:hover{
                background-color: #803792;
            }

            button:disabled {
                background-color: #803792;
            }
        }
    }
}