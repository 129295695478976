form.sign-up-form{
    width: 45%;
    height: fit-content;
    text-align: center;
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;
    background-color: #1E1E1E;
    padding: 1.2em 2em;
    border-radius: 20px;
    z-index: 1;

    div.logo{
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75%;
        height: 30%;

        img{
            margin: auto;
            height: 100%;
            width: 75%;
        }
    }

    .title{
        color: white;
        display: block;
        font-weight: 400;
        font-size: 1.7rem;
    }

    .subtitle{
        color: #afaeae;
        display: block;
        font-weight: 400;
        font-size: 1rem;
    }

    div.flex{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
    }

    input.form-input{
        border-radius: 4px;
        border: none;
        background-color: #464646;
        color: white;
        height: 55px;
    }

    input.form-input:focus{
        border: 1px ridge #8B409D;    
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        cursor: pointer;
        filter: invert(0.8) brightness(100%) sepia(100%) saturate(10%) hue-rotate(20deg);
    }

    .input-password{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 55px;

        input{
            height: 100%;
            width: 80%;
            background-color: #464646;
            color: white;
            border: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        div.eye-password{
            height: 100%;
            background-color: #464646;
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;

            i{
                display: flex;
                align-items: center;
                color: white;
                z-index: 1;
                cursor: pointer;
                font-size: 100%;
            }
        }
    }

    .terms{
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
    
        .check{
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #757575;
            padding-right: 6px;
        }

        .terms-text{
            display: flex;
            justify-content: flex-start;
            font-size: 0.8rem;
            color: white;

            a{
                color: white;
                padding: 0 5px;
            }
        }
    }

    button{
        width: 50%;
        border: none;
        border-radius: 5px;
        background-color: #8B409D;
        font-weight: 500;
    }

    button:hover{
        background-color: #803792;
    }
}

@media screen and (max-width: 1200px) {
    form.sign-up-form{
        width: 55%;
    }
}

@media screen and (max-width: 900px) {
    form.sign-up-form{
        width: 60%;
    }
}

@media screen and (max-width: 800px) {
    form.sign-up-form{
        width: 70%;
    }
}

@media screen and (max-width: 700px) {
    form.sign-up-form{
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    form.sign-up-form{
        width: 90%;
    }
}

@media screen and (max-width: 600px) {
    form.sign-up-form{
        width: 100%;
    }
}