.event-background-banner{
  height: 30vh;
  filter: blur(10px) brightness(85%);
  background-repeat: repeat;
  background-size: auto;
}

.event-banner{
  top: 17vh;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  height: 34vh;
}

button.go-to-cart{
  background-color: #8B409D;
}

button.go-to-cart:hover{
  background-color: #713182;
}

.add-decrease-event{
  cursor: pointer;
}